<template>
  <div class="container" v-if="goodsInfo && goodsInfo.Id">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title-item">商品详情</div>
      <div class="goods-container">
        <div class="goods-info">
          <div class="img img-cover">
            <img v-if="goodsInfo.GoodsThumb" :src="goodsInfo.GoodsThumb" />
            <img v-else src="../../assets/images/no-image-icon.png" />
          </div>
          <div class="name">
            <span class="tag" v-if="goodsInfo.Lable">{{ goodsInfo.Lable }}</span>{{ goodsInfo.GoodsName }}
          </div>
          <div class="money">单价：<span>¥{{ goodsInfo.GoodsPrice }}</span></div>
        </div>
        <div class="order-info">
          <router-link v-if="!userInfo || !userInfo.Id" class="need-login" :to="{path: '/login'}"></router-link>
          <div class="collect-btn img-cover" @click="handleCollection">
            <img v-if="goodsInfo.IsCollect > 0" src="../../assets/pc-images/collect-active-icon.png" />
            <img v-else src="../../assets/pc-images/collect-icon.png" />
          </div>
          <div class="order-nav">
            <div class="nav" :class="orderType == 1 ? 'active' : ''" @click="handleChangeOrderType(1)">普通下单</div>
            <div class="nav" :class="orderType == 2 ? 'active' : ''" @click="handleChangeOrderType(2)">{{ goodsInfo.IsPl != 1 ? '暂停' : '' }}批量下单</div>
          </div>
          <div v-if="orderType == 1" class="order-type-item order-type-1-item">
            <div class="item" v-for="(item, index) in goodsInfo.ParamsTemplate" :key="index">
              <div class="title">{{ item.name }}：</div>
              <div v-if="item.type == 2" class="textarea"><textarea v-model="item.value" :placeholder="item.description" rows="2"></textarea></div>
              <div v-else-if="item.type == 4" class="input"><input v-model="item.value" :placeholder="item.description" type="password" /></div>
              <div v-else-if="item.type == 3 || item.type == 5 || item.type == 8" class="picker">
                <el-select v-model="item.value" :placeholder="item.description">
                  <el-option v-for="(item, index) in item.type_config" :key="index" :label="item" :value="item" />
                </el-select>
              </div>
              <div v-else-if="item.type == 6" class="input"><input v-model="item.value" :placeholder="item.description" type="number" @blur="handleVerifyInput(item, index)" /></div>
              <el-input-number v-else-if="item.type == 7" class="detail" v-model="item.value" :step="item.verify.bs" :min="item.verify.bs || 1" />
              <template v-else-if="item.type == 63">
                <div v-if="item.value && item.isInput" class="input"><input v-model="item.content" readonly @click="item.isInput = false" /></div>
                <div v-else class="input"><input v-model="item.qq" placeholder="QQ号码" type="text" @blur="handleVerifyInput(item, index)" /></div>
              </template>
              <div v-else class="input"><input v-model="item.value" :placeholder="item.description" type="text" @blur="handleVerifyInput(item, index)" /></div>
            </div>
            <div class="item">
              <div class="title">执行方式：</div>
              <div class="zx-type">
                <div class="type" :class="zxType == 1 ? 'active' : ''" @click="zxType = 1">立即执行</div>
                <div v-if="goodsInfo.GoodsType == 1 && zxOrder.dszx == 1" class="type" :class="zxType == 2 ? 'active' : ''" @click="zxType = 2">定时执行</div>
                <div v-if="goodsInfo.GoodsType == 1 && zxOrder.jshzx == 1" class="type" :class="zxType == 3 ? 'active' : ''" @click="zxType = 3">结束后执行</div>
                <div v-if="goodsInfo.GoodsType == 1 && zxOrder.dayzx == 1" class="type" :class="zxType == 4 ? 'active' : ''" @click="zxType = 4">每天执行</div>
              </div>
            </div>
            <div class="item">
              <div class="title"></div>
              <el-date-picker class="zx-detail-2" v-if="zxType == 2" v-model="dszxExpTime" type="datetime" placeholder="选择日期时间"></el-date-picker>
              <div v-if="zxType == 3" class="zx-detail-3">
                <div class="orderno">
                  <input v-model="zxOrderNo" type="text" placeholder="填写之前执行的订单号" />
                </div>
                延后
                <div class="time">
                  <input v-model="exeTime" type="number" />
                </div>
                分钟后执行
              </div>
              <div v-if="zxType == 4" class="zx-detail-4">
                共
                <div class="day">
                  <input v-model="mtzxDay" type="number" min="1" />
                </div>
                天 &nbsp;&nbsp; 每天
                <el-time-picker class="daytime-picker" v-model="mtzxExpTime" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" placeholder="选择时间"></el-time-picker>
              </div>
            </div>
            <div class="item">
              <div class="title">数量：</div>
              <div class="order-count">
                <el-input-number v-model="count" :step="goodsInfo.BuyRate" :min="goodsInfo.BuyMinLimit != -1 ? goodsInfo.BuyMinLimit : goodsInfo.BuyRate" :max="goodsInfo.BuyMaxLimit != -1 ? goodsInfo.BuyMaxLimit : Infinity" />
                <div class="count-tip">下单数量范围 ({{ goodsInfo.BuyMinLimit == -1 ? '不限' : goodsInfo.BuyMinLimit }} - {{ goodsInfo.BuyMaxLimit == -1 ? '不限' : goodsInfo.BuyMaxLimit }})</div>
              </div>
            </div>
            <div class="item">
              <div class="title">总价：</div>
              <div class="total-item">
                ¥ <span class="money">{{ Math.round(money * 100000) / 100000 }}</span>
              </div>
              <div class="btn-item buy" :class="goodsInfo.IsClose == 2 ? '' : 'not'" @click="handleSubmit">
                <van-loading v-if="submiting" color="#ffffff" />
                <span v-else>{{ goodsInfo.IsClose == 2 ? '立即购买' : '暂停购买' }}</span>
              </div>
            </div>
          </div>
          <div v-if="orderType == 2" class="order-type-item order-type-2-item">
            <div class="item">
              <div class="title">购买数量</div>
              <div class="zx-type">
                <div class="type" :class="countType == 1 ? 'active' : ''" @click="countType = 1">不同数量</div>
                <div class="type" :class="countType == 2 ? 'active' : ''" @click="countType = 2">随机数量</div>
                <div class="type" :class="countType == 3 ? 'active' : ''" @click="countType = 3">相同数量</div>
              </div>
            </div>
            <div v-if="countType == 2" class="item">
              <div class="title"></div>
              <div class="count-type-2">
                <div class="input"><input v-model="countType2Min" @input="validateNumber($event, 'countType2Min')" /></div>
                <div class="line"></div>
                <div class="input"><input v-model="countType2Max" @input="validateNumber($event, 'countType2Max')" /></div>
              </div>
            </div>
            <div v-if="countType == 3" class="item">
              <div class="title"></div>
              <el-input-number v-model="countType3Count" :step="goodsInfo.BuyRate" :min="goodsInfo.BuyMinLimit != -1 ? goodsInfo.BuyMinLimit : goodsInfo.BuyRate" :max="goodsInfo.BuyMaxLimit != -1 ? goodsInfo.BuyMaxLimit : Infinity" />
            </div>
            <div class="item">
              <div class="title">下单参数</div>
              <div class="pl-detail">
                <div class="pl-item">
                  <div class="column" v-for="(item, index) in plData" :key="index">
                    <div class="num" @mousedown.prevent="handleTouchStart(index)" @mouseup.prevent="handleTouchEnd(index)">{{ index + 1 }}</div>
                    <div class="input">
                      <input v-model="item.input" :readonly="item.value != ''" type="text" :placeholder="plTip" @blur="handleCheckInput(index)" />
                    </div>
                    <div class="stepper">
                      <el-input-number v-if="item.value" v-model="item.count" :step="goodsInfo.BuyRate" :min="goodsInfo.BuyMinLimit != -1 ? goodsInfo.BuyMinLimit : goodsInfo.BuyRate" :max="goodsInfo.BuyMaxLimit != -1 ? goodsInfo.BuyMaxLimit : Infinity" @change="calcMoney()" />
                    </div>
                  </div>
                </div>
                <div class="tj-item">
                  <div class="small-btn" @click="handleClearPl">全部清空</div>
                  <div class="small-btn" @click="handleAddPl">添加一行</div>
                  <div class="small-btn blue" @click="handleShowImport">批量导入</div>
                  <div class="count">
                    已输入
                    {{ plData | getPlCounnt }}
                    行
                    <el-tooltip effect="light" placement="top">
                      <div slot="content">
                        <div class="pl-tips-popup">
                          <div>长按序号可删除</div>
                          <div>下单格式(一行表示一个订单)</div>
                          <div>{{ plTip }}</div>
                        </div>
                      </div>
                      <img src="../../assets/images/tips-icon.png" />
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="title">总价：</div>
              <div class="total-item">
                ¥ <span class="money">{{ Math.round(money * 100000) / 100000 }}</span>
              </div>
              <div class="btn-item buy" :class="goodsInfo.IsClose == 2 ? '' : 'not'" @click="handleOneSubmit">
                <van-loading v-if="submiting" color="#ffffff" />
                <span v-else>{{ goodsInfo.IsClose == 2 ? '立即购买' : '暂停购买' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-container">
        <div class="order-nav">
          <div class="nav" :class="containerType == 'detail' ? 'active' : ''" @click="containerType = 'detail'">商品详情</div>
          <div class="nav" :class="containerType == 'order' ? 'active' : ''" @click="containerType = 'order'">历史订单</div>
        </div>
        <div v-if="containerType == 'detail'" class="goods-detail">
          <div v-html="goodsInfo.GoodsDetail"></div>
        </div>
        <div v-if="containerType == 'order'" class="history-item">
          <div class="search-item">
            <div class="item">
              <div class="title">时间：</div>
              <div class="input">
                <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;"></el-date-picker>
              </div>
            </div>
            <div class="item">
              <div class="title">下单参数：</div>
              <div class="input">
                <el-input v-model="buyParams" type="text" placeholder="请输入" />
              </div>
            </div>
            <div class="item">
              <div class="title">订单编号：</div>
              <div class="input">
                <el-input v-model="orderSN" type="text" placeholder="请输入" />
              </div>
            </div>
            <div class="item btn-item">
              <div class="btn blue" @click="handleSearch">搜索</div>
              <div class="btn" @click="handleResetOrderList">重置</div>
            </div>
          </div>
          <div class="table-item">
            <el-table :data="orderList" stripe style="width: 100%" height="550">
              <el-table-column prop="OrderStatus" label="订单状态" align="center" width="140">
                <template #default="scope">
                  <el-tooltip v-if="scope.row.OrderStatus == 6 || scope.row.OrderStatus == 7" effect="light" placement="top">
                    <div slot="content">
                      <div style="white-space: nowrap;">退款数量：{{ scope.row.RefundNumber }}</div>
                      <div style="white-space: nowrap;">退款金额：{{ scope.row.RefundAmount }}</div>
                    </div>
                    <span class="status" :class="'status-' + scope.row.OrderStatus" style="cursor: pointer;">{{ orderStatusObj[scope.row.OrderStatus] }}</span>
                  </el-tooltip>
                  <span v-else class="status" :class="'status-' + scope.row.OrderStatus">{{ orderStatusObj[scope.row.OrderStatus] }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="OrderSN" label="订单号" align="center" width="160"></el-table-column>
              <el-table-column prop="BuyParams" label="下单参数" align="center" width="300">
                <template #default="scope">
                  {{ scope.row.BuyParams[0].value }}
                </template>
              </el-table-column>
              <el-table-column prop="BuyNumber" label="下单" align="center" width="100"></el-table-column>
              <el-table-column prop="CompleteNum" label="执行" align="center" width="100">
                <template #default="scope">
                  {{ scope.row.CurrentNum - scope.row.StartNum }}
                </template>
              </el-table-column>
              <el-table-column prop="StartNum" label="初始" align="center" width="100"></el-table-column>
              <el-table-column prop="CurrentNum" label="当前" align="center" width="100"></el-table-column>
              <el-table-column prop="CreateAt" label="下单时间" align="center" width="200">
                <template #default="scope">
                  {{ $TOOL.dateFormat(scope.row.CreateAt * 1000, 'yyyy-MM-dd hh:mm') }}
                </template>
              </el-table-column>
              <el-table-column prop="operate" label="操作" align="center">
                <template #default="scope">
                  <span class="btn" v-if="scope.row.CanTui.includes(`${scope.row.OrderStatus}`) && scope.row.OrderStatus != 4 && scope.row.OrderStatus != 5 && scope.row.OrderStatus != 6 && scope.row.OrderStatus != 7" @click="handleAppRefund(scope.row, scope.$index)">申请退款</span>
                  <span class="btn a" @click="handleShowOrderDetail(scope.row.Id)">订单详情</span>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="total > pageCount" class="pagination-item">
              <el-pagination background layout="prev, pager, next" :page-size="pageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="qqSsPopup.show"
      :round="true"
      position="center"
      title="选择说说"
      @close="handleHideQqShPopup"
    >
      <div class="ss-popup">
        <div class="content">
          <van-list v-model="qqSsPopup.loading" :finished="qqSsPopup.finish" @load="handleGetQqSsDataList" loading-text="加载中" :finished-text="qqSsPopup.finish && !qqSsPopup.loading && qqSsPopup.dataList.length == 0 ? '' : ''" :error-text="'加载失败'">
            <div v-if="qqSsPopup.finish && !qqSsPopup.loading && qqSsPopup.dataList.length == 0" class="empty-item">
              <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
              <div class="name">暂无数据~</div>
            </div>
            <div v-else class="list">
              <div v-for="(item, index) in qqSsPopup.dataList" :key="index" class="item" @click="handleSetQqData(index)">
                <div v-html="item.content"></div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </van-popup>
    <el-dialog title="批量导入" width="480px" :visible.sync="showImport">
      <div class="import-popup">
        <div class="title">
          导入格式(一行表示一个订单)
          <br />
          {{ plTip }}
        </div>
        <div class="textarea">
          <textarea v-model="importValue" rows="6" :placeholder="'下单参数一行一条，格式：' + plTip"></textarea>
        </div>
        <div class="btn-item">
          <div class="btn" @click="handleSureImport">
            <van-loading v-if="importSubmiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <pc-order-detail v-if="dialog.detail" ref="detailDialog" @success="handleSuccess" @closed="dialog.detail=false"></pc-order-detail>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcOrderDetail from '@/components/pcOrderDetail'


export default {
  name: "GoodsDetail",
  components: {
    pcPublicHeader,
    pcOrderDetail
  },
  data() {
    return {
      dialog: {
        detail: false
      },
      id: '',
      goodsInfo: {
        ParamsTemplate: []
      },
      submiting: false,
      addcarting: false,
      zxOrder: {
        dszx: 1,
        jshzx: 1,
        dayzx: 1
      },
      orderType: 1,
      zxType: 1,
      count: 0,
      money: 0,
      pickerData: {
        show: false,
        index: 0,
        key: '',
        name: '',
        data: []
      },
      showDszx: false,
      dszxExpTime: '',
      dszxMinTime: '',
      zxOrderNo: '',
      exeTime: '',
      showMtzx: false,
      mtzxDay: 1,
      mtzxExpTime: '',
      countType: 1,
      countType2Min: '',
      countType2Max: '',
      countType3Count: '',
      plData: [
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
        {input: '', value: '', count: ''},
      ],
      plTip: '',
      importValue: '',
      showImport: false,
      importSubmiting: false,
      touchTimer: null,
      qqSsPopup: {
        show: false,
        qq: '',
        page: '',
        finish: false,
        loading: false
      },
      containerType: 'detail', //detail,order
      orderList: [],
      pageCount: 10,
      page: 1,
      total: 0,
      finish: false,
      loading: false,
      orderSN: '',
      buyParams: '',
      dateRange: [],
      orderParams: {},
      orderStatusObj: { '-1': '待付款', '1': '处理中', '2': '处理中', '3': '处理中', '4': '已完成', '5': '退单中', '6': '已退单', '7': '已退款', '8': '处理中' }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    'goodsInfo.ParamsTemplate': {
      handler: function () {
        this.calcMoney()
      },
      deep: true
    },
    count() {
      this.calcMoney()
    },
    countType3Count(value) {
      const { plData } = this
      for (let i = 0; i < plData.length; i++) {
        const item = plData[i]
        if (item.value) {
          this.plData[i].count = value
        }
      }
      this.calcMoney()
    },
    mtzxDay() {
      this.calcMoney()
    },
  },
  filters: {
    getPlCounnt(val) {
      const list = val.filter(item => item.value)
      return list.length
    }
  },
  mounted() {
    this.handleGetSet()
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.handleGetGoodsDetail()
    }
  },
  methods: {
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleVerifyInput(item, index) {
      let value = item.value.trim()
      this.$set(this.goodsInfo.ParamsTemplate[index], 'value', value)
      if (item.type == 6 && !this.$TOOL.numberRegex(value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 21 && !this.$TOOL.qqRegex(value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 22 && !this.$TOOL.phoneRegex(value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 23 && !this.$TOOL.emailRegex(value)) {
        this.$toast(item.name + '格式不正确')
        this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        return
      }
      if (item.type == 61 && value) {
        let res = await this.handleGetDouYinMasterUrl(value)
        if (res.error == 0 && res.info) {
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', res.info)
        } else {
          this.$toast(item.name + '格式不正确')
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        }
        return
      }
      if (item.type == 62 && value) {
        let res = await this.handleGetdouyinkuaishouid(value)
        if (res.error == 0 && res.info) {
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', res.info)
        } else {
          this.$toast(item.name + '格式不正确')
          this.$set(this.goodsInfo.ParamsTemplate[index], 'value', '')
        }
        return
      }
      if (item.type == 63 && item.qq) {
        const loading = this.$loading({
          background: 'rgba(0,0,0,.3)'
        })
        let res = await this.handleGetSS(item.qq, 1)
        loading.close()
        res = JSON.parse(res)
        if (res.code == 0) {
          this.qqSsPopup = {
            show: true,
            index: index,
            qq: item.qq,
            page: 1,
            finish: res.data.length == 0,
            loading: false,
            dataList: res.data
          }
        } else {
          this.$toast(res.msg)
        }
        return
      }
      
    },
    async handleGetQqSsDataList() {
      const { qqSsPopup } = this
      const page = qqSsPopup.page + 1
      let res = await this.handleGetSS(qqSsPopup.qq, page)
      res = JSON.parse(res)
      if (res.code == 0) {
        this.qqSsPopup.dataList = [
          ...qqSsPopup.dataList,
          ...res.data
        ]
        this.qqSsPopup.page = page
        this.qqSsPopup.finish = !res.data || res.data == 0
        this.qqSsPopup.loading = false
      } else {
        this.$toast(res.msg)
      }
    },
    handleSetQqData(index) {
      const { qqSsPopup, orderType } = this
      if (orderType == 1) {
        this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'value', qqSsPopup.dataList[index].tid)
        this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'content', qqSsPopup.dataList[index].content)
        this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'isInput', true)
      }
      this.qqSsPopup.show = false
    },
    handleHideQqShPopup() {
      const { qqSsPopup, orderType } = this
      if (orderType == 1) {
        if (this.goodsInfo.ParamsTemplate[qqSsPopup.index].value) {
          this.$set(this.goodsInfo.ParamsTemplate[qqSsPopup.index], 'isInput', true)
        }
      }
    },
    handleTouchStart(index) {
      const { plData } = this
      if (plData[index].value) {
        if (this.touchTimer) {
          clearTimeout(this.touchTimer)
        }
        this.touchTimer = setTimeout(() => {
          this.handleRemovePl(index)
        }, 1000);
      }
    },
    handleTouchEnd() {
      if (this.touchTimer) {
        clearTimeout(this.touchTimer)
        this.touchTimer = null
      }
    },
    handleShowImport() {
      this.importValue = ''
      this.showImport = true
      this.importSubmiting = false
    },
    handleSureImport() {
      const { importSubmiting, importValue, plData } = this
      if (importValue == '' || importSubmiting) {
        return
      }
      const importList = importValue.split('\n')
      let importIndex = 0
      for (let index = 0; index < plData.length; index++) {
        const item = plData[index]
        if (!item.value) {
          if (importList[importIndex]) {
            this.plData[index].input = importList[importIndex]
            importIndex++
            this.handleCheckInput(index)
          } else {
            this.handleCancelImport()
            return
          }
        }
      }

      if (importList.length > importIndex) {
        for (let i = importIndex; i < importList.length; i++) {
          const item = importList[i];
          this.plData.push({input: item, value: '', count: ''})
          this.handleCheckInput(this.plData.length - 1)
        }
      }
      this.handleCancelImport()
    },
    handleCancelImport() {
      this.showImport = false
    },
    handleChangeOrderType(type) {
      if (this.goodsInfo.IsPl != 1 && type == 2) {
        return
      }
      this.orderType = type
      this.calcMoney()
    },
    handleCheckInput(index) {
      const obj = this.plData[index]
      if (!obj.input) {
        this.plData[index].value = ''
        return
      }
      const input = obj.input.split('----').map(item => {
        return item.trim()
      })
      const { countType, countType2Min, countType2Max, countType3Count, goodsInfo } = this
      const { ParamsTemplate } = goodsInfo
      for (let i = 0; i < ParamsTemplate.length; i++) {
        const item = ParamsTemplate[i]
        if (item.type == 6 && !this.$TOOL.numberRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else if (item.type == 7) {
          if (!/^[1-9]\d*$/.test(input[i])) {
            this.$toast(item.name +  '必须是数字')
            return
          }
          const number = parseInt(input[i])
          const bs = parseInt(item.verify.bs || 1)
          if (number % bs != 0) {
            this.$toast(item.name +  '必须是' + bs + '的倍数')
            return
          }
        } else if (item.type == 3 || item.type == 5 || item.type == 8) {
          if (!item.type_config.includes(input[i])) {
            this.$toast(item.name +  '必须是选项中的值')
            return
          }
        } else if (item.type == 21 && !this.$TOOL.qqRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else if (item.type == 22 && !this.$TOOL.phoneRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else if (item.type == 23 && !this.$TOOL.emailRegex(input[i])) {
          this.$toast(item.name + '格式不正确')
          return
        } else {
          if (!input[i]) {
            this.$toast(item.description)
            return
          }
        }
      }
      let count = 0
      if (input.length > ParamsTemplate.length) {
        count = input[input.length - 1]
        input.splice(input.length - 1, 1)
      }
      this.plData[index].input = input.join('----')
      this.plData[index].value = input.join('----')
      if (count) {
        this.plData[index].count = count
      } else {
        if (countType == 1) {
          this.plData[index].count = goodsInfo.BuyMinLimit || goodsInfo.BuyRate
        }
        if (countType == 2 && countType2Min && countType2Max) {
          let min = parseInt(countType2Min)
          let max = parseInt(countType2Max)
          this.plData[index].count = parseInt(min + (Math.random() * (max - min)))
        }
        if (countType == 3) {
          this.plData[index].count = countType3Count || goodsInfo.BuyMinLimit || goodsInfo.BuyRate
        }
      }
      this.calcMoney()
    },
    handleClearPl() {
      this.$dialog.confirm({
        type: 'danger',
        className: 'pc-dialog',
        title: '提示',
        message: '是否清除所有数据',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.plData = [
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''},
          {input: '', value: '', count: ''}
        ]
      }).catch(() => {
      })
    },
    handleRemovePl(index) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '提示',
        message: '确定要删除吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(() => {
        this.plData.splice(index, 1)
        if (this.plData.length < 5) {
          this.plData.push({input: '', value: '', count: ''})
        }
      }).catch(() => {
      })
    },
    handleAddPl() {
      this.plData.push({input: '', value: '', count: ''})
    },
    datetimeFilter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 5 === 0)
      }
      return options
    },
    handleShowMtzx() {
      this.showMtzx = true
    },
    handleSureMtzxTime(value) {
      this.mtzxExpTime = value
      this.handleCancelMtzxTime()
    },
    handleCancelMtzxTime() {
      this.showMtzx = false
    },
    handleShowDszx() {
      this.dszxMinTime = new Date()
      this.showDszx = true
    },
    handleSureDszxTime(value) {
      this.dszxExpTime = this.$TOOL.dateFormat(value, 'yyyy-MM-dd hh:mm')
      this.handleCancelDszxTime()
    },
    handleCancelDszxTime() {
      this.showDszx = false
    },
    handleShowPicker(item, index) {
      this.pickerData = {
        show: true,
        index,
        key: item.key,
        name: item.name,
        data: item.type_config
      }
    },
    handleSurePicker(val) {
      const { pickerData } = this
      this.goodsInfo.ParamsTemplate[pickerData.index].value = val
      this.handleCancelPicker()
    },
    handleCancelPicker() {
      this.pickerData = {
        show: false,
        index: 0,
        key: '',
        name: '',
        data: []
      }
    },
    async handleGetSet() {
      var res = await this.$API.getSet.post({
        Key: 'zx_order'
      })
      if (res.info) {
        const data = JSON.parse(res.info)
        this.zxOrder = data
      }
    },
    async handleGetGoodsDetail() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        return
      }
      const { id, websiteInfo } = this
      var res = await this.$API.goodsDetail.post({
        Id: id,
        AdminId: websiteInfo.Id
      })
      // res.info.ParamsTemplate = `[{"key":"text","description":"普通文本","name":"普通文本","type":1,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"textarea","description":"多行文本","name":"多行文本","type":2,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"pwd","description":"密码","name":"密码","type":4,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"select","description":"多选","name":"多选","type":5,"type_config":"多选1,多选2,多选3,多选4","value":"","verify":{"max":-1,"min":0}},{"key":"number","description":"数字","name":"数字","type":6,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"count","description":"累乘","name":"累乘","type":7,"type_config":"","value":"","verify":{"max":-1,"min":0,"bs":2}},{"key":"countSelect","description":"累乘下拉","name":"累乘下拉","type":8,"type_config":"1,3,5,7","value":"","verify":{"max":-1,"min":0}},{"key":"qq","description":"QQ","name":"QQ","type":21,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"phone","description":"手机号","name":"手机号","type":22,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"email","description":"邮箱","name":"邮箱","type":23,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"url","description":"链接","name":"链接","type":61,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"id","description":"ID","name":"ID","type":62,"type_config":"","value":"","verify":{"max":-1,"min":0}},{"key":"ssid","description":"说说","name":"说说","type":63,"type_config":"","value":"","verify":{"max":-1,"min":0}}]`
      if (res.error == 0) {
        try {
          res.info.ParamsTemplate = JSON.parse(res.info.ParamsTemplate)
        } catch (error) {
          console.log(error)
        }
        const plTipArr = []
        if (res.info.ParamsTemplate) {
          for (let i = 0; i < res.info.ParamsTemplate.length; i++) {
            const item = res.info.ParamsTemplate[i]
            if (item.type == 3 || item.type == 5 || item.type == 8) {
              res.info.ParamsTemplate[i].type_config = item.type_config.split(',')
            }
            plTipArr.push(item.name)
          }
        } else {
          res.info.ParamsTemplate = []
        }
        plTipArr.push('数量');
        this.plTip = plTipArr.join('----')
        res.info.BuyRate = res.info.BuyRate || 1
        this.count = res.info.BuyMinLimit > res.info.BuyRate ? res.info.BuyMinLimit : res.info.BuyRate
        this.goodsInfo = res.info
        this.getOrderList()
        if (res.info.IsTc == 1) {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: res.info.TcTitle,
            message: res.info.TcContent,
            confirmButtonText: '确定',
          })
        }
        this.calcMoney()
      }
    },
    async handleCollection() {
      const { goodsInfo } = this
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: goodsInfo.Id
      })
      if (res.error == 0) {
        this.goodsInfo.IsCollect = goodsInfo.IsCollect > 0 ? 0 : 1
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    },
    calcMoney() {
      const { goodsInfo, orderType, zxType, mtzxDay } = this
      if (orderType == 1) {
        const { count } = this
        if (!count) {
          this.money = 0
          return
        }
        let number = parseInt(count)
        const { ParamsTemplate } = goodsInfo
        if (ParamsTemplate && ParamsTemplate.length > 0) {
          for (let i = 0; i < ParamsTemplate.length; i++) {
            const item = ParamsTemplate[i]
            if (item.type == 7 || item.type == 8) {
              if (item.value) {
                number = number * parseInt(item.value)
              } else {
                this.money = 0
                return
              }
            }
          }
        }
        let money = parseFloat(goodsInfo.GoodsPrice) * number
        if (zxType == 4) {
          money = money * parseInt(mtzxDay)
        }
        money = isNaN(money) ? 0 : money
        this.money = money
      }
      if (orderType == 2) {
        const { plData } = this
        let number = 0
        for (let i = 0; i < plData.length; i++) {
          const pl = plData[i]
          if (pl.value) {
            let plNumber = parseInt(pl.count)
            const value = pl.value.split('----')
            const { ParamsTemplate } = goodsInfo
            if (ParamsTemplate && ParamsTemplate.length > 0) {
              for (let j = 0; j < ParamsTemplate.length; j++) {
                const item = ParamsTemplate[j]
                if (item.type == 7 || item.type == 8) {
                  plNumber = plNumber * parseInt(value[j])
                }
              }
            }
            number = number + plNumber
          }
        }
        this.money = parseFloat(goodsInfo.GoodsPrice) * number
      }
    },
    async handleGetDouYinMasterUrl(data) {
      var res = this.$API.getDouYinMasterUrl.post({
        Url: data
      })
      return res
    },
    async handleGetdouyinkuaishouid(data) {
      var res = this.$API.getdouyinkuaishouid.post({
        Url: data
      })
      return res
    },
    async handleGetSS(qq, page) {
      var res = await this.$API.getSS.post({
        Qq: qq,
        Page: page
      })
      return res
    },
    async handleAddCart() {
      const { addcarting, goodsInfo, count } = this
      if (addcarting) {
        return
      }
      const orderParams = []
      for (let i = 0; i < goodsInfo.ParamsTemplate.length; i++) {
        const item = goodsInfo.ParamsTemplate[i]
        let value = item.value
        if (!item.value) {
          this.$toast(item.description)
          return
        }
        orderParams.push({
          name: item.name,
          alias: item.key,
          type: item.type,
          value: value
        })
      }
      const params = {
        Switch: 1,
        GoodsId: goodsInfo.Id,
        Count: count,
        Params: JSON.stringify(orderParams)
      }
      this.addcarting = true
      var res = await this.$API.addCar.post(params)
      this.addcarting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
    async handleSubmit() {
      const { submiting, goodsInfo, zxType, count, dszxExpTime, zxOrderNo, exeTime, mtzxDay, mtzxExpTime, money } = this
      if (goodsInfo.IsClose == 1) {
        return
      }
      if (submiting) {
        return
      }
      this.submiting = true
      setTimeout(() => {
        const orderParams = []
        for (let i = 0; i < goodsInfo.ParamsTemplate.length; i++) {
          const item = goodsInfo.ParamsTemplate[i]
          let value = item.value
          if (!item.value) {
            this.$toast(item.name + '不能为空')
            this.submiting = false
            return
          }
          orderParams.push({
            name: item.name,
            alias: item.key,
            value: value
          })
        }
        const params = {
          GoodsIds: goodsInfo.Id,
          ZxType: zxType,
          OrderNum: count,
          OrderParams: JSON.stringify([orderParams])
        }
        if (zxType == 1) {
          params.ExpTime = this.$TOOL.dateFormat(new Date())
        }
        if (zxType == 2) {
          if (!dszxExpTime) {
            this.$toast('请选择执行时间')
            this.submiting = false
            return
          }
          params.ExpTime = this.$TOOL.dateFormat(dszxExpTime)
        }
        if (zxType == 3) {
          if (!zxOrderNo) {
            this.$toast('请输入之前执行的订单号')
            this.submiting = false
            return
          }
          if (exeTime === '') {
            this.$toast('请输入延时执行时间')
            this.submiting = false
            return
          }
          params.ExeTime = exeTime
          params.ZxOrderNo = zxOrderNo
        }
        if (zxType == 4) {
          if (!mtzxDay) {
            this.$toast('请输入执行天数')
            this.submiting = false
            return
          }
          if (!mtzxExpTime) {
            this.$toast('请选择执行时间')
            this.submiting = false
            return
          }
          const max = parseInt(mtzxDay)
          for (let i = 0; i < max; i++) {
            if (i == 0) {
              params.ExpTime = this.$TOOL.dateFormat(new Date(), 'yyyy-MM-dd') + ' ' + mtzxExpTime
              params.OrderParams = [orderParams]
            } else {
              params.GoodsIds += ',' + goodsInfo.Id
              params.OrderNum += ',' + count
              params.ExpTime += ',' + this.$TOOL.dateFormat((new Date().getTime()) + (i * 24 * 3600 * 1000), 'yyyy-MM-dd') + ' ' + mtzxExpTime
              params.OrderParams.push(orderParams)
            }
          }
          params.OrderParams = JSON.stringify(params.OrderParams)
        }
        this.handleSubmitOrder(params, money)
      }, 800);
    },
    async handleOneSubmit() {
      const { submiting, goodsInfo, plData, money } = this
      if (goodsInfo.IsClose == 1) {
        return
      }
      const { ParamsTemplate } = goodsInfo
      if (submiting) {
        return
      }
      const plList = plData.filter(item => item.value)
      if (plList.length == 0) {
        this.$toast('请输入下单参数')
        return
      }
      const orderParams = []
      const goodsIds = []
      const orderNum = []
      const expTime = []
      this.submiting = true
      for (let plIndex = 0; plIndex < plList.length; plIndex++) {
        const pl = plList[plIndex]
        goodsIds.push(goodsInfo.Id)
        orderNum.push(pl.count)
        expTime.push(this.$TOOL.dateFormat(new Date(), 'yyyy-MM-dd hh:mm'))
        const value = pl.value.split('----')
        const plParams = []
        for (let paramsIndex = 0; paramsIndex < ParamsTemplate.length; paramsIndex++) {
          const item = ParamsTemplate[paramsIndex]
          plParams.push({
            name: item.name,
            alias: item.key,
            value: value[paramsIndex]
          })
        }
        orderParams.push(plParams)
      }
      const params = {
        OrderParams: JSON.stringify(orderParams),
        GoodsIds: goodsIds.join(','),
        OrderNum: orderNum.join(','),
        ExpTime: expTime.join(','),
        ZxType: 1
      }
      this.handleSubmitOrder(params, money)
    },
    handleSubmitOrder(params, money) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '是否确认交易?',
        message: '本次兑换将扣除您' + (Math.round(money * 100000) / 100000) + '元余额',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(async () => {
        var res = await this.$API.addOrder.post(params)
        this.submiting = false
        if (res.error == 0) {
          const data = JSON.parse(res.info)
          let html = '<div><span style="margin-right: 10px;">已提交：' + data.length + '</span>'
          let successList = data.filter(item => item.error == 0)
          html += '<span style="color: #22e1ad; margin-right: 10px;">成功：' + successList.length + '</span>'
          let errorList = data.filter(item => item.error != 0)
          html += '<span style="color: #e12b20; margin-right: 10px;">失败：' + errorList.length + '</span></div>'
          for (let index = 0; index < data.length; index++) {
            const item = data[index]
            if (item.error == 0) {
              html += '<div style="color: #22e1ad;">' + item.msg + '</div>'
            } else {
              html += '<div style="color: #e12b20;">' + item.msg + '</div>'
            }
          }
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: html,
            confirmButtonText: '确定',
          }).then(() => {
            window.location.reload()
          })
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      }).catch(() => {
        this.submiting = false
      })
    },
    handleSearch() {
      const { orderSN, buyParams, dateRange } = this
      const params = {}
      if (orderSN) {
        params.OrderSN = orderSN
      }
      if (buyParams) {
        params.BuyParams = buyParams
      }
      if (dateRange && dateRange.length > 0) {
        var startTime = dateRange[0]
        var endTime = dateRange[1]
        params.StartTime = this.$TOOL.dateFormat(startTime,'yyyy-MM-dd')
        params.EndTime = this.$TOOL.dateFormat(endTime,'yyyy-MM-dd')
      }
      this.orderParams = params
      this.page = 1
      this.total = 0
      this.orderList = []
      this.finish = false
      this.loading = false
      this.getOrderList()
    },
    handleResetOrderList() {
      this.orderSN = ''
      this.buyParams = ''
      this.dateRange = []
      this.orderParams = {}
      this.page = 1
      this.total = 0
      this.finish = false
      this.loading = false
      this.orderList = []
      this.getOrderList()
    },
    handleCurrentPageChange(val) {
      this.page = val
      this.getOrderList()
    },
    async getOrderList() {
      const { pageCount, page, orderParams, goodsInfo } = this
      const params = {
        Id: goodsInfo.Id,
        PageCount: pageCount, 
        Page: page,
        ...orderParams
      }
      var res = await this.$API.orderList.post(params)
      const data = []
      for (let index = 0; index < res.info.length; index++) {
        const item = res.info[index]
        data.push({
          ...item,
          CanTui: item.CanTui.split(','),
          BuyParams: JSON.parse(item.BuyParams)
        })
      }
      this.total = res.count
      this.orderList = [
        ...data
      ]
      this.finish = data.length < pageCount
      this.loading = false
    },
    async handleAppRefund(item) {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '申请退款',
        message: '确定要申请退款吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(async () => {
      var res = await this.$API.appRefund.post({
          Id: item.Id
        })
        if (res.error == 0) {
          this.$toast.success(res.info)
          this.getOrderList()
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      }).catch(() => {
      })
    },
    handleSuccess() {
      this.getOrderList()
    },
    handleShowOrderDetail(id) {
      this.dialog.detail = true
      this.$nextTick(() => {
        this.$refs.detailDialog.open(id)
      })
    }
  }
};
</script>

<style lang="less" scoped>
.page-title-item {
  padding: 30px 0 24px;
  line-height: 46px;
  color: #3d3d42;
  font-size: 32px;
  font-weight: bold;
}

.order-nav {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .nav {
    width: 124px;
    height: 64px;
    border-radius: 8px 8px 0 0;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    &.active {
      color: #6a5ffe;
      font-weight: bold;
      background-color: #fff;
      box-shadow: 4px -4px 12px 0px rgba(0, 0, 0, .06);
    }
  }
}
.goods-container {
  display: flex;
  border-radius: 8px;
  background-color: #fafbff;
  .goods-info {
    padding: 20px;
    margin-right: 10px;
    width: 200px;
    .img {
      width: 200px;
      height: 200px;
      overflow: hidden;
    }
    .name {
      margin-top: 16px;
      line-height: 26px;
      font-size: 20px;
      color: #3d3d42;
      padding: 4px 0;
      .tag {
        float: left;
        margin-top: 2px;
        margin-right: 6px;
        line-height: 20px;
        font-size: 12px;
        padding: 0 8px;
        border-radius: 6px;
        color: #fff;
        background: linear-gradient( 315deg, #FFAA8A 0%, #FFDE58 100%);
      }
    }
    .money {
      margin-top: 12px;
      color: #999;
      font-size: 14px;
      line-height: 30px;
      span {
        font-size: 28px;
        font-weight: bold;
        color: #fe346e;
      }
    }
  }
  .order-info {
    flex: 1;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .need-login {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
    .collect-btn {
      cursor: pointer;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
    .order-type-item {
      padding: 16px 30px 30px;
      background-color: #fff;
    }
    .order-type-item {
      &.order-type-1-item {
        .textarea {
          box-sizing: border-box;
          width: 932px;
          height: 80px;
          border: 1px solid #ccc;
          border-radius: 6px;
          padding: 10px 20px;
          background-color: #f5f5f5;
        }
        .input {
          box-sizing: border-box;
          width: 520px;
          height: 40px;
          border: 1px solid #ccc;
          border-radius: 6px;
          padding: 0 20px;
          background-color: #f5f5f5;
        }
        .picker {
          /deep/.el-input {
            box-sizing: border-box;
            width: 520px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 0 20px;
            background-color: #f5f5f5;
            .el-input__inner {
              background-color: transparent;
              padding: 0;
              border: none;
            }
          }
        }
      }
      &.order-type-2-item {
        .count-type-2 {
          display: flex;
          align-items: center;
          .input {
            box-sizing: border-box;
            width: 84px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 6px;
            input {
              text-align: center;
            }
          }
          .line {
            width: 14px;
            height: 1px;
            background-color: #3d3d42;
            margin: 0 40px;
          }
        }
        .pl-detail {
          width: 932px;
          font-size: 14px;
          .pl-item {
            border: 1px solid #ccc;
            background-color: #f7f7f7;
            border-radius: 6px;
            overflow: hidden;
            .column {
              height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .num {
                width: 40px;
                height: 40px;
                background-color: #fff;
                border-right: 1px solid #ccc;
                text-align: center;
                line-height: 40px;
                color: #b3b3b3;
                font-size: 14px;
              }
              .input {
                flex: 1;
                padding: 0 14px;
              }
              .stepper {
                margin-right: 20px;
                /deep/.el-input-number {
                  line-height: 28px;
                  .el-input__inner {
                    line-height: 30px;
                    height: 30px;
                  }
                }
              }
            }
          }
          .tj-item {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .small-btn {
              cursor: pointer;
              width: 105px;
              height: 33px;
              background-color: #e9edf5;
              border-radius: 6px;
              line-height: 33px;
              text-align: center;
              font-weight: bold;
              color: #71757d;
              margin-right: 20px;
              &.blue {
                background-color: #6a5ffe;
                color: #fff;
              }
            }
            .count {
              flex: 1;
              padding: 0 20px;
              color: #3d3d42;
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-left: 8px;
              }
            }
          }
          //     btn-item
        }
      }
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
        .title {
          width: 88px;
          color: #3d3d42;
          font-size: 14px;
        }
        .zx-type {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .type {
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 24px;
            font-size: 14px;
            color: #3d3d42;
            height: 40px;
            line-height: 40px;
            border: 1px solid #ccc;
            border-radius: 6px;
            margin-right: 20px;
            &.active {
              font-weight: bold;
              border-color: #3d3d42;
              background-color: rgba(106, 95, 254, .16);
            }
          }
        }
        .zx-detail-2 {
          width: 520px;
          /deep/.el-input__inner {
            background-color: #f5f5f5;
          }
        }
        .zx-detail-3 {
          display: flex;
          align-items: center;
          .orderno {
            box-sizing: border-box;
            width: 210px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 0 20px;
            background-color: #f5f5f5;
          }
          .time {
            box-sizing: border-box;
            width: 120px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 0 20px;
            background-color: #f5f5f5;
          }
        }
        .zx-detail-4 {
          display: flex;
          align-items: center;
          .day {
            box-sizing: border-box;
            width: 120px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 0 20px;
            background-color: #f5f5f5;
          }
          .daytime-picker {
            width: 200px;
            /deep/.el-input__inner {
              background-color: #f5f5f5;
            }
          }
        }
        .order-count {
          display: flex;
          align-items: center;
          .count-tip {
            margin-left: 20px;
            color: #999;
            font-size: 14px;
          }
        }
        .total-item {
          flex: 1;
          font-size: 18px;
          font-weight: bold;
          color: #fe346e;
          .money {
            font-size: 28px;
          }
        }
        .btn-item {
          cursor: pointer;
          width: 178px;
          height: 48px;
          background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
          text-align: center;
          line-height: 48px;
          color: #fff;
          font-weight: normal;
          font-size: 14px;
          border-radius: 6px;
          &.not {
            background: #fe346e;
          }
        }   
      }
    }
  }
}
.ss-popup {
  padding: 24px 0;
  .content {
    width: 50vw;
    height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    .item {
      cursor: pointer;
      border-radius: 10px;
      padding: 10px;
      margin: 10px 12px;
      box-shadow: 0 0 8px 2px rgba(0, 28, 84, .1);
      color: #666;
      line-height: 20px;
    }
  }
}
/deep/.el-tooltip__popper.is-light {
  background-color: #e6e6e6 !important;
}
.pl-tips-popup {
  width: 304px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
}
.import-popup {
  .title {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .textarea {
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 12px;
  }
  .btn-item {
    margin-top: 20px;
    border-top: 1px solid #f3f5f8;
    padding: 16px 0 0;
    .btn {
      cursor: pointer;
      width: 100%;
      height: 36px;
      text-align: center;
      line-height: 36px;
      border-radius: 4px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
      color: #fff;
      font-size: 12px;
    }
  }
}
.order-container {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  .goods-detail {
    margin: 20px 30px;
    padding: 20px;
    // background-color: #d9d9d9;
    color: #0b212c;
    font-size: 14px;
    line-height: 24px;
    /deep/ img {
      max-width: 100% !important;
      height: auto !important;
    }
  }
  .history-item {
    padding: 20px 40px 20px 30px;
    font-size: 14px;
    color: #3d3d42;
    .search-item {
      display: flex;
      align-items: center;
      .item {
        flex: 1;
        display: flex;
        align-items: center;
        &.btn-item {
          justify-content: flex-end;
        }
        .title {
          width: 100px;
          text-align: right;
        }
        .input {
          width: 272px;
          height: 36px;
          overflow: hidden;
          /deep/.el-date-editor {
            .el-range__icon,
            .el-range-separator {
              line-height: 28px;
            }
          }
          /deep/.el-input__inner {
            height: 36px;
            line-height: 36px;
          }
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 106px;
          height: 36px;
          border: 1px solid #3d3d42;
          border-radius: 6px;
          font-size: 14px;
          font-weight: bold;
          color: #3d3d42;
          background-color: #fff;
          margin-left: 14px;
          cursor: pointer;
          &.blue {
            color: #fff;
            border: 0;
            background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
          }
        }
      }
    }
    .table-item {
      margin-top: 20px;
      .status {
        color: #46d59f;
        &.status-4 {
          color: #3d3d42;
        }
        &.status-5,
        &.status-6,
        &.status-7 {
          color: #fe346e;
        }
      }
      .btn {
        cursor: pointer;
        margin: 0 10px;
        color: #71757d;
        &.a {
          color: #3d3d42;
        }
      }
    }
  }
}
</style>
